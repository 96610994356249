var items = $('.items-records-blog .item-record');

window.onload = function () {
  console.log(items);
};

window.addEventListener('scroll', function () {
  //console.log(pageYOffset);
  items.each(function (i, item) {
    if (item.offsetTop + item.offsetHeight / 2 < pageYOffset) //+ 
      {
        console.log({
          pageYOffset: pageYOffset,
          item: item
        });
        var domItem = $(item);

        if (!domItem.hasClass("action")) {
          domItem.addClass("action");
        } //console.log(item);

      }
  }); // document.getElementById('showScroll').innerHTML = pageYOffset + 'px';
});